import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link } from 'react-router-dom';
import { FaBookReader } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";


function Home() {
  const [data,setData] = useState([]);
  const [deleted, setDeleted] = useState(true)

  useEffect(()=>{
    if(deleted){
        setDeleted(false)
    axios.get('/api')
    .then((res)=>{
        setData(res.data)
    })
    .catch((err)=>console.log(err))
}
}, [deleted])

  function handleDelete(id){
    axios.delete(`/api/delete/${id}`)
    .then((res)=>{
        setDeleted(true)
    })
    .catch((err)=> console.log(err))
}

  return (
    <div className='container bg-white vw-100 mt-4'>
       <div className='align-items-center bg-white'>
       <h3>Students</h3>
      <div className=''>
      <Link to="/create" className='btn btn-light btn-md border'>Create</Link>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((student) => {
              return <tr>
                <td>{student.id}</td>
                <td>{student.email}</td>
                <td>
                                <Link className="m-2" to={`/read/${student.id}`}><FaBookReader /></Link>
                                <Link className="" to={`/edit/${student.id}`}><CiEdit/></Link>
                                <Link className='m-1' onClick={()=>handleDelete(student.id)}><MdDeleteOutline /></Link>
                            </td>
              </tr>
            })
          }

        </tbody>

      </table>

        </div>
    </div>
  )
}

export default Home