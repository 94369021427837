import React, { useState } from 'react';
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

function Create() {
  const [values,setValues] = useState({
    name:'',email:''
  });
  const navigate = useNavigate()

  function handleSubmit(e){
    e.preventDefault()

    axios.post('/api/add_user', values)
    .then((res)=>{
        
        navigate('/')
        console.log(res)
    })
    .catch((err)=>console.log(err))
}

  return (
    <div className='bg-primary m-2'>
     <div className='d-flex bg-white align-items-center justify-content-center'>
     <div className="row">
        <h3>Add Student</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group my-3">
           <label>Name</label>
           <input type='text' name='name' required  
           onChange={(e) => setValues({...values, name: e.target.value})}/>
          </div>
          <div className="form-group my-3">
           <label>Email</label>
           <input type='email' name='email' required 
            onChange={(e) => setValues({...values, email: e.target.value})}/>
          </div>
          <div className='form-group my-3'>
                    <button type='submit' className='btn btn-dark btn-md'>Save</button>
                </div>
        </form>
      </div>

     </div>

    </div>
  )
}

export default Create