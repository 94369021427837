import React from 'react';
import "../components/style.css";
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div className='container under'>
      <h3>Under Construction</h3>
     <p>
      We're working hard to make this website available again on Wednesday 24th August 2024.
      You will then find a new design and our new collection!
      Until then we look forward to seeing you on our <Link to="https://www.linkedin.com/in/hassan-m-6ba3a6164/">linkedin</Link>
     </p>



    </div>
  )
}

export default Contact