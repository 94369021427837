import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";
import "./App.css"
import { CiLight } from "react-icons/ci";

function Navpanel() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary container">
      <Container fluid>
        <Navbar.Brand className='m-1' to="/" as={Link}><CiLight />HNur</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 text-center"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Link to="/" className='m-2'>Home</Link>
            <Link to="/contact" className='m-2'>Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
   
  )
}

export default Navpanel