import React from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./elements/Home";
import Create from "./elements/Create";
import Read from "./elements/Read";
import Edit from "./elements/Edit";
import Navpanel from './components/Navpanel';
import Footer from './components/Footer';
import Contact from './components/Contact';



function App() {
  return (
    <BrowserRouter>
   <Navpanel/>
   <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/create' element={<Create />}/>
      <Route path='/edit/:id' element={<Edit />}/>
      <Route path='/read/:id' element={<Read />}/>
      <Route path='/contact' element={<Contact />}/>
    </Routes>
   <Footer/>
    </BrowserRouter>
  )
}

export default App
